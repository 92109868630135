<template>
  <div>
    <validation-observer ref="simpleRules">
      <!-- <this-header /> -->
      <div class="anan-set-nav-conttainer mt-150">
        <div class="row">
          <div class="col-md-2 col-lg-2 col-sm-12">
            <side-bar class="d-none d-md-block" />
          </div>

          <div class="col-md-10 col-lg-10 col-sm-12">
            <div>
              <b-button
                class="btn btn-gradient-primary mb-1 mr-50"
                @click="$router.go(-1)"
              >
                <i class="fas fa-arrow-left mr-25" /> {{ $t('returnToList') }}
              </b-button>
              <b-button
                class="btn btn-gradient-success mb-1 ml-25 mr-50"
                @click="Submit()"
              >
                <i class="far fa-save mr-50" /> {{ $t('saveData') }}
              </b-button>

              <b-row class="match-height">
                <b-col md="6">

                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-50">
                        {{ $t('details') }} {{ $route.params.id }}
                      </h3>
                    </div>

                    <div class="p-2">
                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              <span class="text-danger">*</span> {{ $t('chineseBankAccount') }}
                            </template>
                            <span v-if="itemsbank">
                              {{ $t('key-55') }}: {{ itemsbank.name_bank }} <br>
                              {{ $t('accountName') }}: {{ itemsbank.name_account }} <br>
                              {{ $t('accountNumber') }}: {{ itemsbank.number_account }} <br>
                              {{ $t('branch') }}: {{ itemsbank.branch ? itemsbank.branch: '-' }} <br>
                              <div
                                class="text-warning"
                                @click="$bvModal.show('modal-bank-list')"
                              >
                                <feather-icon
                                  icon="Edit3Icon"
                                  class="mr-1 text-primary cursor-pointer"
                                /> {{ $t('edit') }}
                              </div>

                            </span>
                            <button
                              v-else
                              class="btn-add-bank"
                              @click="$bvModal.show('modal-bank-list')"
                            >
                              <i class="far fa-plus mr-25" /> {{ $t('selectChineseBankAccount') }}
                            </button>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="basic-addon3"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              <span class="text-danger">*</span> {{ $t('transferAmount') }}
                            </template>
                            <validation-provider
                              #default="{ errors }"
                              name="File"
                              rules="required"
                            >
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="basic-addon3"
                                  v-model="price"
                                  type="number"
                                  placeholder=""
                                />
                                <b-input-group-append is-text>
                                  ¥
                                </b-input-group-append>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] ? $t('requiredField'): '' }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('exchangeRate') }}
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              ¥1 = {{ General.rate_payment }} ฿
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('maxExchangeAmount') }}
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              ¥ {{ Commas(sumitem = (Number(Balance.balance) / Number(General.rate_payment)) ) }}
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-paecelCode"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('systemBalance') }}
                            </template>
                            <p class="mt-50 font-weight-bolder">
                              {{ Commas(Balance.balance) }} ฿
                            </p>
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-file1"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('attachedFile') }}
                            </template>
                            <!-- <validation-provider
                              #default="{ errors }"
                              name="File"
                              rules="required"
                            > -->
                            <b-form-file
                              id="h-file1"
                              v-model="filecus"
                              accept=".jpg, .png, .jpeg"
                              placeholder="Choose a file or drop it here..."
                              drop-placeholder="Drop file here..."
                              @change="fileImage"
                            />
                            <span class="mt-2 d-flex justify-content-center">
                              <span
                                v-if="filecusimg"
                                class="preview-image-containerv2 mr-2 d-flex justify-content-center"
                              >
                                <img
                                  :src="filecusimg"
                                  alt="Preview"
                                  class=""
                                  @click="showPicture(filecusimg)"
                                >
                                <div class="preview-image-overlays p-0">
                                  <i
                                    class="fas fa-times-circle cursor-pointer"
                                    @click="deleteImageorder()"
                                  />
                                </div>
                              </span>
                            </span>
                            <!-- <small class="text-danger">{{ errors[0] ? 'จำเป็นต้องแนบไฟล์': '' }}</small>
                            </validation-provider> -->
                          </b-form-group>
                        </b-col>

                        <b-col md="12">
                          <b-form-group
                            label-for="h-remark"
                            label-cols="5"
                            label-cols-lg="5"
                            label-cols-md="5"
                            class="mb-50"
                          >
                            <template v-slot:label>
                              {{ $t('remarks') }}
                            </template>
                            <b-form-textarea
                              id="h-remark"
                              v-model="remark"
                              type="text"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>
                </b-col>

                <b-col md="6">
                  <div class="card">
                    <div class="header-cardx p-2">
                      <h3 class="mb-50">
                        {{ $t('rateInformation') }}
                      </h3>
                    </div>

                    <div class="pl-2 pr-2">
                      <b-table
                        responsive="sm"
                        :fields="fieldsRelevant"
                        :items="[General]"
                        show-empty
                      >
                        <template #empty="">
                          <b-table-empty-slot>
                            <div class="text-center my-2">
                              <img
                                src="@/assets/images/anan-img/svg/doc_empty.svg"
                                alt="empty"
                                height="60px"
                              >
                              <p class="text-muted">
                                No Data
                              </p>
                            </div>
                          </b-table-empty-slot>
                        </template>

                        <template #cell(rate)="data">
                          {{ data.item.rate_payment }}
                          <!-- <span class="text-success">
                            {{ data.value }} min_price
                          </span> -->
                        </template>

                        <template #cell(min_price)="">
                          0
                          <!-- {{ data.item.rate_payment }} -->
                          <!-- <span class="text-success">
                            {{ data.value }}
                          </span> -->
                        </template>
                      </b-table>
                    </div>

                  </div>
                </b-col>
              </b-row>

              <!-- modal bank list -->
              <b-modal
                id="modal-bank-list"
                :title="$t('selectChineseBankAccount')"
                size="lg"
                :ok-title="$t('key-124')"
                :cancel-title="$t('cancel')"
                @ok="addBank()"
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  @click="EditType()"
                >
                  <i class="fal fa-plus" /> {{ $t('addInfo') }}
                </button>
                <div class="pl-2 pr-2">
                  <b-table
                    responsive="sm"
                    :fields="fieldsbank"
                    :items="bankchina"
                    show-empty
                  >
                    <template #empty="">
                      <b-table-empty-slot>
                        <div class="text-center my-2">
                          <img
                            src="@/assets/images/anan-img/svg/doc_empty.svg"
                            alt="empty"
                            height="60px"
                          >
                          <p class="text-muted">
                            No Data
                          </p>
                        </div>
                      </b-table-empty-slot>
                    </template>

                    <!-- <template #cell(rate)="data">
                      {{ data.item.rate_payment }} -->
                    <!-- <span class="text-success">
                            {{ data.value }} min_price
                          </span> -->
                    <!-- </template> -->

                    <template #cell(set)="data">
                      <b-form-checkbox
                        v-model="checkeditem"
                        :value="data.item"
                        :unchecked-value="null"
                      />
                    </template>
                    <template #cell(branch)="data">
                      {{ data.item.branch ? data.item.branch: '-' }}
                    </template>

                    <template #cell(actions)="data">
                      <feather-icon
                        icon="Edit3Icon"
                        class="mr-1 text-primary cursor-pointer"
                        @click="EditType(data.item)"
                      />

                      <feather-icon
                        icon="TrashIcon"
                        class="text-danger cursor-pointer"
                        @click="DeleteData(data.item)"
                      />
                      <!-- {{ data.item.branch ? data.item.branch: '-' }} -->
                    </template>
                  </b-table>

                </div>
              </b-modal>
              <b-modal
                id="modal-receive-add-amend"
                :title="$t('key-157')"
                @ok.prevent="addNewbank()"
              >
                <validation-observer ref="addNewbank">
                  <b-row>
                    <b-col md="12">
                      <!-- <b-form-group
                        label-for="h-name_bank"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          <span class="text-danger"> *</span> {{ $t('key-55') }}
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="full name"
                          rules="required"
                        >
                          <b-form-input
                            id="h-name_bank"
                            v-model="name_bank"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] ? $t('requiredField')+'!':'' }}</small>
                        </validation-provider>
                      </b-form-group> -->
                      <b-form-group
                        label-for="h-name_bank"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          <span class="text-danger"> *</span> {{ $t('key-55') }}
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="Bank"
                          rules="required"
                        >
                          <b-form-input
                            id="h-name_Bank"
                            v-model="name_bank"
                            :state="errors.length > 0 ? false:null"
                          />
                          <span class="text-danger">{{ errors[0] ? $t('requiredField')+'!':'' }}</span>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-addresstex"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          <span class="text-danger"> *</span> {{ $t('accountName') }}
                        </template>

                        <validation-provider
                          #default="{ errors }"
                          name="name_account"
                          rules="required"
                        >
                          <b-form-input
                            id="h-name_account"
                            v-model="name_account"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] ? $t('requiredField')+'!':'' }}</small>
                        </validation-provider>

                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-number_account"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          <span class="text-danger"> *</span> {{ $t('accountNumber') }}
                        </template>

                        <validation-provider
                          #default="{ errors }"
                          name="number_account"
                          rules="required"
                        >
                          <b-form-input
                            id="h-number_account"
                            v-model="number_account"
                            :state="errors.length > 0 ? false:null"
                          />
                          <small class="text-danger">{{ errors[0] ? $t('requiredField')+'!':'' }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-branch"
                        label-cols="3"
                        label-cols-lg="3"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          {{ $t('branch') }}
                        </template>
                        <!-- <validation-provider
                          #default="{ errors }"
                          name="Taxpayer"
                          rules="required"
                        > -->
                        <b-form-input
                          id="h-branch"
                          v-model="branch"
                        />
                        <!-- <small class="text-danger">{{ errors[0] ? 'จำเป็นต้องกรอก!':'' }}</small> -->
                        <!-- </validation-provider> -->
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-modal>

            </div>
          </div>
        </div>
      </div>
    </validation-observer>

    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BButton,
  BFormFile,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BTable,
} from 'bootstrap-vue'
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'
import SideBar from '../component/SideBar.vue'

export default {
  name: 'LineNotify',
  components: {
    ValidationObserver,
    ValidationProvider,
    SideBar,
    // ThisHeader,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BTable,
    vSelect,
    VueImageLightboxCarousel,
  },
  data() {
    return {
      images: [],
      showLightbox: false,
      required,
      fileadmin: [],
      filecus: null,
      remark: null,
      General: [],
      branch: null,
      name_account: null,
      name_bank: null,
      set: null,
      number_account: null,
      bankchina: [],
      Balance: [],
      id: null,
      checkeditem: null,
      itemsbank: null,
      filecusimg: null,
      price: 0,
      sumitem: 0,
      bank_id: null,

    }
  },
  computed: {
    fieldsRelevant() {
      return [
        {
          key: 'rate', label: this.$t('exchangeRate'), thStyle: { width: '50%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        { key: 'min_price', label: this.$t('threshold'), thStyle: { width: '50%' } },
      ]
    },
    fieldsbank() {
      return [
        {
          key: 'set', label: '', thStyle: { width: '5%' }, thClass: 'text-center',
        },
        {
          key: 'name_bank', label: this.$t('key-55'), thStyle: { width: '15%' }, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'name_account', label: this.$t('accountName'), thStyle: { width: '15%' }, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'number_account', label: this.$t('accountNumber'), thStyle: { width: '15%' }, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'branch', label: this.$t('branch'), thStyle: { width: '15%' }, tdClass: 'text-center', thClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('manage'), thStyle: { width: '10%' }, tdClass: 'text-center', thClass: 'text-center',
        },

      ]
    },
  },
  mounted() {
    this.getGeneral()
    this.getBank()
    this.getBalance()
    this.getSelect()
  },
  methods: {
    async getSelect() {
      try {
        const { data: res } = await this.$http.get('/bank')
        this.itemBank = res
      } catch (e) {
        console.log(e)
      }
    },
    async getGeneral() {
      try {
        const { data: res } = await this.$http.get('/general/getData')
        if (res.status) {
          this.General = res.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getBank() {
      try {
        const { data: res } = await this.$http.get('/BankChina/bank')
        this.bankchina = res
        // console.log(res)
      } catch (err) {
        this.SwalError(err)
      }
    },
    async getBalance() {
      try {
        const { data: res } = await this.$http.get('/Balance/getbalanceOne')
        this.Balance = res
        // console.log(this.Balance)
      } catch (err) {
        console.log(err)
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (!this.itemsbank) {
            this.SwalError('กรุณาเลือกบัญชีธนาคารจีน')
          } else if (this.CommasUp(this.price) > this.CommasUp(this.sumitem)) {
            this.SwalError('จำนวนเงินในระบบไม่เพียงพอ')
          } else {
            const obj = {
              name_bank: this.itemsbank.name_bank,
              name_account: this.itemsbank.name_account,
              number_account: this.itemsbank.number_account,
              branch: this.itemsbank.branch,
              amount: this.price,
              rate: this.General.rate_payment,
              balance: this.CommasUp(this.price * this.General.rate_payment),
              filecus: this.filecusimg,
              remark_cus: this.remark,
            }
            this.$http.post('/BankChina/storedata', obj)
              .then(response => {
                if (response.data.status) {
                  this.Success('บันทึกข้อมูลเรียบร้อย')
                  this.$router.go(-1)
                } else {
                  this.SwalError(response.data.message)
                }
              })
              .catch(err => {
                this.SwalError(err.response.data.message)
              })
            // console.log(obj)
          }
        }
      })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('ลบข้อมูลบัญชีธนาคารจีนนี้?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      }).then(result => {
        if (result) {
          const obj = {
            // eslint-disable-next-line no-underscore-dangle
            id: data._id,
          }
          this.$http.post('/BankChina/DeleteData', obj)
            .then(() => {
              this.getBank()
              this.Success('ลบบัญชีธนาคารเรียบร้อย')
            })
            .catch(err => {
              console.log(err)
              this.SwalError(err)
            })
        }
      })
      // console.log(data)
    },
    addBank() {
      if (this.checkeditem) {
        this.itemsbank = this.checkeditem
      } else {
        this.checkeditem = null
        this.itemsbank = null
      }
      // this.$bvModal.hide('modal-bank-list')
    },
    EditType(data) {
      if (data) {
        // eslint-disable-next-line no-underscore-dangle
        this.id = data._id
        this.name_bank = data.name_bank
        this.name_account = data.name_account
        this.number_account = data.number_account
        this.branch = data.branch
      } else {
        this.bank_id = null
        this.id = null
        this.name_bank = null
        this.name_account = null
        this.number_account = null
        this.branch = null
      }
      this.$bvModal.show('modal-receive-add-amend')
      // console.log(data)
    },
    fileImage(event) {
      if (event) {
        this.filecus = event.target.files
        // this.fileimg_th = null
        const reader = new FileReader()
        const filepath = this.filecus[0]
        if (!filepath) {
          this.filecusimg = null
        } else {
          reader.onload = e => {
            // console.log(e.target.result)
            // console.log(e.target.result)
            this.filecusimg = e.target.result
            // console.log(this.filecusimg)
            // e.target.result
            // console.log(this.namemap)
          }
          reader.readAsDataURL(filepath)
        }
      }

      // console.log(event)
    },
    addNewbank() {
      this.$refs.addNewbank.validate().then(success => {
        if (success) {
          // console.log('success')
          const obj = {
            id: this.id,
            name_bank: this.name_bank,
            name_account: this.name_account,
            number_account: this.number_account,
            branch: this.branch,
          }
          // console.log(obj)
          this.$http.post('/BankChina/storebank', obj)
            .then(() => {
              this.getBank()
              this.Success('บันทึกข้อมูลเรียบร้อย')
              this.$bvModal.hide('modal-receive-add-amend')
            })
            .catch(err => {
              this.SwalError(err)
              console.log(err)
            })
        }
      })
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: picture }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 400,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    deleteImageorder() {
      this.filecusimg = null
      this.filecus = null
    },
    // Editband() {
    //   this.$bvModal.show('modal-receive-add-amend')
    // },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    // this.$bvModal.show('modal-receive-add-amend')
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
